.product-section-products {
  padding: 80px;
  background-color: #ffffff;
  text-align: center;
  margin-bottom: 45px;
}

.section-title-products {
  font-size: 33px;
  margin-bottom: 30px;
  color: #222;
  position: relative;
  display: inline-block;
  margin-top: 10px;
  font-weight: 900;
}

.section-title-products::after {
  content: '';
  width: 100px;
  height: 3px;
  background-color: #1a73e8;
  display: block;
  margin: 8px auto 0;
  border-radius: 2px;
}

.product-list-products {
  width: 70%;
  margin: 0 auto;
  /* Centra el contenedor horizontalmente */
  display: flex;
  justify-content: center;
  gap: 20px;
  /* Reducción del gap entre los productos */
  flex-wrap: wrap;
}


.product-card-products {
  background-color: #fff;
  border-radius: 15px;
  /* Reducción del border-radius */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 220px;
  /* Reducción del tamaño máximo de la tarjeta */
  max-height: auto;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 320px;
}

.product-card-products:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.image-container-products {
  overflow: hidden;
  border-bottom: 1px solid #e0e0e0;
}

.product-image-products {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.product-card-products:hover .product-image-products {
  transform: scale(1.05);
  /* Reducción del efecto de zoom */
}

.product-name-products {
  font-size: 1.2rem;
  /* Reducción del tamaño de la fuente */
  margin: 10px 0 8px;
  padding: 0 15px;
  color: #333;
}

.product-description-products {
  font-size: 0.85rem;
  /* Reducción del tamaño de la fuente */
  padding: 0 15px 15px;
  color: #777;
}

.product-price-products {
  display: block;
  font-size: 1.1rem;
  /* Reducción del tamaño de la fuente */
  margin: 10px 15px;
  color: #1a73e8;
  font-weight: bold;
  margin-top: -20px;
}

.buy-button-products {
  display: block;
  width: calc(100% - 30px);
  margin: 0 15px 15px;
  color: #fff;
  background: linear-gradient(135deg, #1a73e8, #00a0d1);
  border: none;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  position: relative;
}

.buy-button-products:hover {
  background: linear-gradient(135deg, #00a0d1, #007bbf);
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
}

.buy-button-products:active {
  transform: translateY(-1px);
}

@media screen and (max-width: 768px) {
  .product-list-products {
    width: 100%;
  }

  .product-card-products {
    min-width: 120%;
    height: auto;
  }

  .section-title-products {
    font-size: 24px;
    margin-bottom: 30px;
    width: 120%; 
    margin-left: -20px;
  }
}