.div {
  background-color: #ffffff;
}

.header-container.menu-open {
  margin-top: 200px; /* Ajusta según el tamaño del menú */
}

/* Ajustes de transición */
.header-container {
  transition: margin-top 0.3s ease;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 20px;
  margin-top: 10px;
}

.main-content {
  margin-top: 0;
  transition: margin-top 0.3s ease;
}

/* Estilos generales para la cabecera */
.header {
  background: linear-gradient(to top, #cccccc, #256ece, #1370FB, #1370fb);
  padding: 100px 0;
  color: white;
  border-bottom-left-radius: 80%;
  border-bottom-right-radius: 80%;
  overflow: hidden;
  position: relative;
}

.header-content {
  text-align: start;
  margin-left: 100px;
  margin-top: -15px;
  opacity: 0;
  transform: translateX(-100%);
  animation: slideInLeft 1.2s ease-out forwards;
}

@keyframes slideInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.header-image-container {
  text-align: right;
  margin-right: 100px;
  opacity: 0;
  transform: translateX(100%);
  animation: slideInRight 1.2s ease-out forwards;
}

@keyframes slideInRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.header-content h1 {
  font-size: 48px;
  margin-top: 20px;
}

.header-content p {
  font-size: 20px;
  margin-bottom: 40px;
}

.contact-button {
  background-color: white;
  color: #007bff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  transition: all 0.3s ease;
}

.contact-button:hover {
  transform: scale(1.05);
  background-color: #007bff;
  color: white;
}

.header-image {
  width: 70%;
  height: auto;
  margin-top: -40px;
  margin-right: 60px;
}

/* Estilos para vista móvil */
@media (max-width: 768px) {
  /* Ajusta la separación en móviles */
  .header-container.menu-open {
    margin-top: 270px; /* Ajusta según el tamaño del menú */
  }

  .header {
    padding: 60px 0; /* Hacer el header más largo en móvil */
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }

  .header-content {
    margin-left: 0;
    transform: none;
    animation: none;
    opacity: 1;
    max-width: 90%;
    text-align: center;
  }

  .header-content h1 {
    font-size: 28px; /* Hacer el texto más pequeño */
    margin-top: 10px;
  }

  .header-content p {
    font-size: 16px; /* Texto más pequeño */
    margin-bottom: 20px;
  }

  .contact-button {
    padding: 8px 15px;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 0;
    text-align: center;
  }

  .header-image-container {
    margin: 10px 0;
    text-align: center;
    margin-bottom: -20px;
  }

  .header-image {
    width: 60%; /* Tamaño más pequeño en móvil */
    margin: 0;
  }
}