.nuestra-empresa-us {
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  color: #333;
  margin-top: 80px;
  margin-bottom: 120px;
}

.columna-izquierda-us {
  width: 48%; /* Ancho de la columna izquierda */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  opacity: 0; /* Empieza oculto */
  transform: translateX(-100%); /* Mueve fuera de la pantalla a la izquierda */
  animation: slideInLeft 1.2s ease-out forwards;
  animation-delay: 0.3s;
}

.columna-derecha-us {
  width: 48%; /* Ancho de la columna derecha */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  opacity: 0; /* Empieza oculto */
  transform: translateX(100%); /* Mueve fuera de la pantalla a la derecha */
  animation: slideInRight 1.2s ease-out forwards;
  animation-delay: 0.6s;
}

.titulo-grande-us,
.descripcion-us,
.carrusel-container-us,
.imagen-container-abajo-us,
.titulo-vision-us,
.vision-us {
  opacity: 0;
  transform: translateX(-100%); /* Mueve hacia la derecha */
  animation: slideInRight 1.2s ease-out forwards;
}

.imagen-container-us,
.titulo-mision-us,
.mision-us,
.imagen-container-abajo-us2 {
  opacity: 0;
  transform: translateX(100%); /* Mueve hacia la izquierda */
  animation: slideInLeft 1.2s ease-out forwards;
}

@keyframes slideInLeft {
  to {
    opacity: 1;
    transform: translateX(0); /* Mueve a su posición original */
  }
}

@keyframes slideInRight {
  to {
    opacity: 1;
    transform: translateX(0); /* Mueve a su posición original */
  }
}

.titulo-grande-us {
  color: #1370fb;
  font-size: 38px;
  font-weight: 900;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 100%;
}

.descripcion-us {
  font-size: 20px;
  color: #333;
  width: 100%;
  text-align: center;
}

.titulo-vision-us {
  color: #1370fb;
  font-size: 38px;
  font-weight: 900;
  margin-bottom: 0px;
  margin-top: -100px;
  width: 100%;
  margin-left: 40px;
  text-align: left;
}

.vision-us {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  width: 100%;
  margin-left: 40px;
  text-align: left;
}

.titulo-mision-us {
  color: #1370fb;
  font-size: 38px;
  font-weight: 900;
  margin-bottom: 0px;
  margin-top: -80px;
  width: 100%;
  margin-right: 80px;
  text-align: right;
}

.mision-us {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  width: 100%;
  margin-right: 80px;
  text-align: right;
}

.carrusel-container-us {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%; /* Mantén el ancho adecuado para mostrar 2 cards centradas */
  position: relative;
}

.directores-carrusel-us {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 20px 0;
  gap: 15px;
  scrollbar-width: none; /* Firefox */
}

.directores-carrusel-us::-webkit-scrollbar {
  display: none; /* Chrome/Safari */
}

.directores-cards-us {
  display: flex;
  justify-content: center; /* Centra las cards dentro del carrusel */
  align-items: center;
  gap: 15px; /* Mantén el espacio entre las cards */
  transition: transform 0.5s ease-in-out;
  margin: 40px;
}

.director-card-us {
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  width: 180px;
  height: 240px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
}

.director-card-us:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.carrusel-btn-us {
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: #1370fb;
  cursor: pointer;
  z-index: 1;
  transition: color 0.3s ease, transform 0.3s ease;
  position: absolute;
}

.carrusel-btn-us.prev-us {
  left: -60px; /* Ajusta la posición de la flecha izquierda */
  top: 50%;
  transform: translateY(-50%);
}

.carrusel-btn-us.next-us {
  right: -60px; /* Ajusta la posición de la flecha derecha */
  top: 50%;
  transform: translateY(-50%);
}

.director-icon-us img {
  width: calc(100% - 10px);
  height: 150px;
  border-radius: 20px;
  object-fit: cover;
}

.director-card-us h3 {
  color: #1370fb;
  font-size: 16px;
  font-weight: 800;
}

.director-card-us h4 {
  font-size: 12px;
  color: #777;
  margin-top: 0px;
}

.imagen-container-us {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imagen-central-us {
  width: 450px;
  height: auto;
  object-fit: contain;
  margin-top: -150px;
}

.imagen-container-abajo-us,
.imagen-container-abajo-us2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -80px;
}

.imagen-central-abajo-us1,
.imagen-central-abajo-us2 {
  width: 450px;
  height: auto;
  object-fit: contain;
  margin-top: -60px;
}

@media screen and (max-width: 768px) {
  .nuestra-empresa-us {
    flex-direction: column;
    align-items: center;
  }

  .columna-izquierda-us {
    width: 100%;
    margin-left: 0;
    margin-top: -20px;
  }

  .titulo-grande-us {
    font-size: 30px;
  }

  .descripcion-us {
    font-size: 18px;
  }

  .carrusel-container-us {
    width: 100%;
    margin-bottom: -80px;
  }

  .director-card-us {
    width: 120px;
    height: 160px;
  }

  .director-icon-us img {
    width: 90%;
    height: 90%;
  }

  .director-card-us h3 {
    font-size: 11px;
    margin-top: auto;
  }

  .director-card-us h4 {
    font-size: 10px;
    margin-top: auto;
  }

  .prev-us {
    display: none;
  }

  .next-us {
    display: none;
  }

  .titulo-vision-us,
  .vision-us {
    display: none;
  }

  .imagen-central-abajo-us1 {
    display: none;
  }

  .imagen-central-us {
    width: 200px;
    height: auto;
    margin-top: -80px;
    margin-left: 0px;
  }

  .columna-derecha-us {
    width: 100%;
    margin-top: 20px;
  }

  .imagen-central-abajo-us2 {
    display: none;
  }
}

